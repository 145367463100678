var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"appie-features-area pt-100",attrs:{"id":"features"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"appie-features-tabs-btn"},[_c('div',{staticClass:"nav flex-column nav-pills",attrs:{"role":"tablist","aria-orientation":"vertical"}},[_c('a',{staticClass:"nav-link",class:[
                                _vm.selectedTab === 'setting' ? 'active' : '',
                            ],attrs:{"data-toggle":"pill","role":"tab","aria-controls":"v-pills-home","aria-selected":"true"},on:{"click":function($event){$event.preventDefault();return _vm.selectFeature('setting')}}},[_c('i',{staticClass:"iconfont icon-zhengshu"}),_vm._v(" 资质认定证书")]),_c('a',{staticClass:"nav-link",class:[
                                _vm.selectedTab === 'report' ? 'active' : '',
                            ],attrs:{"data-toggle":"pill","role":"tab","aria-controls":"v-pills-profile","aria-selected":"false"},on:{"click":function($event){$event.preventDefault();return _vm.selectFeature('report')}}},[_c('i',{staticClass:"iconfont icon-shiyan"}),_vm._v(" 实验室认可证书")]),_c('a',{staticClass:"nav-link",class:[
                                _vm.selectedTab === 'notice' ? 'active' : '',
                            ],attrs:{"data-toggle":"pill","role":"tab","aria-controls":"v-pills-messages","aria-selected":"false"},on:{"click":function($event){$event.preventDefault();return _vm.selectFeature('notice')}}},[_c('i',{staticClass:"iconfont icon-shiyan"}),_vm._v(" 实验室认可证书英文版")]),_c('a',{staticClass:"nav-link",class:[
                                _vm.selectedTab === 'lock' ? 'active' : '',
                            ],attrs:{"data-toggle":"pill","role":"tab","aria-controls":"v-pills-settings","aria-selected":"false"},on:{"click":function($event){$event.preventDefault();return _vm.selectFeature('lock')}}},[_c('i',{staticClass:"iconfont icon-gongzhengchu"}),_vm._v(" 公正性声明 ")])])])]),_c('div',{staticClass:"col-lg-9"},[_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"tab-pane fade",class:[
                            _vm.selectedTab === 'setting' ? 'active show' : '',
                        ],attrs:{"role":"tabpanel","aria-labelledby":"v-pills-home-tab"}},[_vm._m(0)]),_c('div',{staticClass:"tab-pane fade",class:[
                            _vm.selectedTab === 'report' ? 'active show' : '',
                        ],attrs:{"role":"tabpanel","aria-labelledby":"v-pills-profile-tab"}},[_vm._m(1)]),_c('div',{staticClass:"tab-pane fade",class:[
                            _vm.selectedTab === 'notice' ? 'active show' : '',
                        ],attrs:{"role":"tabpanel","aria-labelledby":"v-pills-messages-tab"}},[_vm._m(2)]),_c('div',{staticClass:"tab-pane fade",class:[
                            _vm.selectedTab === 'lock' ? 'active show' : '',
                        ],attrs:{"role":"tabpanel","aria-labelledby":"v-pills-settings-tab"}},[_vm._m(3)])])])])]),_vm._m(4),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"appie-features-thumb text-center wow animated fadeInUp",attrs:{"data-wow-duration":"2000ms","data-wow-delay":"200ms"}},[_c('img',{attrs:{"src":"http://www.dlhxzb.cn/new/static/uploads/20180823060515_598841854043765346.jpg","alt":""}})])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"appie-features-content wow animated fadeInRight",attrs:{"data-wow-duration":"2000ms","data-wow-delay":"600ms"}},[_c('span',[_vm._v("内蒙古恒鑫产品质量检验中心")]),_c('h3',{staticClass:"title"},[_vm._v("资质认定证书")]),_c('p',[_vm._v(" China Inspection Body and Laboratory Mandatory Approval ")]),_c('a',{staticClass:"main-btn",attrs:{"href":"#"}},[_vm._v(" 了解更多 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"appie-features-thumb text-center animated fadeInUp",attrs:{"data-wow-duration":"2000ms","data-wow-delay":"200ms"}},[_c('img',{attrs:{"src":"http://www.dlhxzb.cn/new/static/uploads/20180823060825_133592571539293771.jpg","alt":""}})])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"appie-features-content animated fadeInRight",attrs:{"data-wow-duration":"2000ms","data-wow-delay":"600ms"}},[_c('span',[_vm._v("内蒙古恒鑫产品质量检验中心")]),_c('h3',{staticClass:"title"},[_vm._v("实验室认可证书")]),_c('p',[_vm._v(" China National Accreditation Service for Conformity Assessment ")]),_c('a',{staticClass:"main-btn",attrs:{"href":"#"}},[_vm._v(" 了解更多 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"appie-features-thumb text-center animated fadeInUp",attrs:{"data-wow-duration":"2000ms","data-wow-delay":"200ms"}},[_c('img',{attrs:{"src":"http://www.dlhxzb.cn/new/static/uploads/20180823061342_308845867096824895.jpg ","alt":""}})])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"appie-features-content animated fadeInRight",attrs:{"data-wow-duration":"2000ms","data-wow-delay":"600ms"}},[_c('span',[_vm._v("内蒙古恒鑫产品质量检验中心")]),_c('h3',{staticClass:"title"},[_vm._v(" 实验室认可证书英文版 ")]),_c('p',[_vm._v(" China National Accreditation Service for Conformity Assessment ")]),_c('a',{staticClass:"main-btn",attrs:{"href":"#"}},[_vm._v(" 了解更多 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"appie-features-thumb text-center animated fadeInUp",attrs:{"data-wow-duration":"2000ms","data-wow-delay":"200ms"}},[_c('img',{attrs:{"src":"http://www.dlhxzb.cn/new/uploads/4.jpg","alt":""}})])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"appie-features-content animated fadeInRight",attrs:{"data-wow-duration":"2000ms","data-wow-delay":"600ms"}},[_c('span',[_vm._v("内蒙古恒鑫产品质量检验中心")]),_c('h3',{staticClass:"title"},[_vm._v("公正性声明")]),_c('p',[_vm._v(" 科学、公正、准确、及时是我公司的质量方针 ")]),_c('a',{staticClass:"main-btn",attrs:{"href":"#"}},[_vm._v(" 了解更多 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"features-shape-1"},[_c('img',{attrs:{"src":require("@/assets/images/shape/shape-6.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"features-shape-2"},[_c('img',{attrs:{"src":require("@/assets/images/shape/shape-7.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"features-shape-3"},[_c('img',{attrs:{"src":require("@/assets/images/shape/shape-8.png"),"alt":""}})])
}]

export { render, staticRenderFns }