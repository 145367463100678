<template>
  <error-com/>
</template>

<script>
import ErrorCom from '../components/ErrorCom.vue'
export default {
	components: { ErrorCom },

}
</script>

<style>

</style>