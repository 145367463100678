<template>
    <home-Three/>
</template>

<script>
import HomeThree from '../components/HomeThree/index'
export default {
	components: {HomeThree  },

}
</script>

<style>

</style>