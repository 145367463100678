<template>
          <!-- Contact Start -->
        <section class="contact-section">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="contact--info-area">
                            <h3>Get in touch</h3>
                            <p>
                                Looking for help? Fill the form and start a new adventure.
                            </p>
                            <div class="single-info">
                                <h5>Headquaters</h5>
                                <p>
                                    <i class="fal fa-home"></i>
                                    744 New York Ave, Brooklyn, Kings,<br> New York 10224
                                </p>
                            </div>
                            <div class="single-info">
                                <h5>Phone</h5>
                                <p>
                                    <i class="fal fa-phone"></i>
                                    (+642) 245 356 432<br>
                                    (+420) 336 476 328
                                </p>
                            </div>
                            <div class="single-info">
                                <h5>Support</h5>
                                <p>
                                    <i class="fal fa-envelope"></i>
                                    bisy@support.com<br>
                                    help@education.com
                                </p>
                            </div>
                            <div class="ab-social">
                                <h5>Follow Us</h5>
                                <a class="fac" href="#"><i class="fab fa-facebook-f"></i></a>
                                <a class="twi" href="#"><i class="fab fa-twitter"></i></a>
                                <a class="you" href="#"><i class="fab fa-youtube"></i></a>
                                <a class="lin" href="#"><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="contact-form">
                            <h4>Let’s Connect</h4>
                            <p>Integer at lorem eget diam facilisis lacinia ac id massa.</p>
                            <form action="#" method="post" class="row">
                                <div class="col-md-6">
                                    <input type="text" name="f-name" placeholder="First Name">
                                </div>
                                <div class="col-md-6">
                                    <input type="text" name="l-name" placeholder="Last Name">
                                </div>
                                <div class="col-md-6">
                                    <input type="email" name="email" placeholder="Email Address">
                                </div>
                                <div class="col-md-6">
                                    <input type="number" name="phone" placeholder="Phone Number">
                                </div>
                                <div class="col-md-12">
                                    <input type="text" name="suject" placeholder="Subject">
                                </div>
                                <div class="col-md-12">
                                    <textarea name="message" placeholder="How can we help?"></textarea>
                                </div>
                                <div class="col-md-6">
                                    <div class="condition-check">
                                        <input id="terms-conditions" type="checkbox">
                                        <label for="terms-conditions">
                                            I agree to the <a href="#">Terms & Conditions</a>      
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6 text-right">
                                    <input type="submit" name="submit" value="Send Message">
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Contact End -->
</template>

<script>
export default {

}
</script>

<style>

</style>