<template>
  <about-two />
</template>

<script>
import AboutTwo from "../components/AboutUsTwo/index.vue";
export default {
  components: { AboutTwo },
};
</script>

<style>
</style>