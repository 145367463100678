<template>
    <div>
        <b-modal
            id="notice0"
            class="notices"
            title="证书召回公告"
            :centered="true"
            @ok="close"
            cancel-title="取消"
            ok-title="我知道了"
            size-bak="sm"
        >
            <p style="text-indent: 2em">
                因本公司系统错误，2022年7月22日至7月23日出具的证书编号为HX2220722*****~HX2220723*****的证书全部作废召回，请持有该批证书的顾客联系我们重新出具证书。给您造成的不便，敬请谅解！
            </p>
            <p style="text-indent: 2em">联系电话：15947133306</p>
            <p class="mt-30" align="right">
                内蒙古恒鑫产品质量检验中心有限公司<br />
                2022年7月24日
            </p>
        </b-modal>
        <b-modal
            id="notice1"
            class="notices"
            title="更正公告"
            :centered="true"
            @ok="close"
            cancel-title="取消"
            ok-title="我知道了"
            size-bak="sm"
        >
            <p style="text-indent: 2em">
                因系统故障，2022年6月26日和2022年7月2日出具的检测证书部分批次作废，需要重新核发证书。有相关需要的客户请统一联系本公司实验室，将统一处理核发。因此给大家造成的不便，我们深表歉意。
            </p>
            <p style="text-indent: 2em">联系电话：15947133306</p>
            <p class="mt-30" align="right">
                内蒙古恒鑫产品质量检验中心有限公司<br />
                2022年7月6日
            </p>
        </b-modal>
    </div>
</template>

<script>
export default {
    props: {
        menuItems: {
            type: Array,
        },
        nasted: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            query_id: "",
        };
    },
    methods: {
        close() {
            // return bvModalEvent.preventDefault();
        },
    },
};
</script>

<style>
.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
}
button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}
</style>
