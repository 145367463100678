var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"appie-pricing-area pt-90 pb-90"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"appie-section-title text-center"},[_c('h3',{staticClass:"appie-title"},[_vm._v(_vm._s(_vm.title))]),_c('p',[_vm._v(_vm._s(_vm.description))])])])]),_c('div',{staticClass:"tabed-content d-flex flex-column align-items-center justify-content-center main-order"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"d-flex align-items-center",class:[index % 2 == 0 ? 'order-left' : 'order-right']},[_c('div',{staticClass:"appie-single-service text-center mt-30 wow animated fadeInUp pt-10 pb-10 pl-30 pr-30",class:[
                        item.customClass && item.customClass !== ''
                            ? item.customClass
                            : '',
                        index % 2 == 0 ? 'order-1' : 'order-2',
                    ],attrs:{"data-wow-duration":"2000ms","data-wow-delay":"200ms"}},[_c('h4',{staticClass:"appie-title p-0 item-title"},[_vm._v(" "+_vm._s(item.title)+" ")])]),(index < 10)?_c('div',{staticClass:"ml-20 mr-20 mt-30",class:[index % 2 == 0 ? 'order-2' : 'order-1']},[_c('span',{staticClass:"step-icon"},[_vm._v(" "+_vm._s(index + 1)+" ")])]):_vm._e()])}),0),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"faq-text text-center pt-40"},[_c('p',[_vm._v(" 联系地址：呼和浩特市赛罕区新华东街26号万达广场B商业综合楼14层B座1403室 ")])])])
}]

export { render, staticRenderFns }