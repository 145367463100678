<template>
    <service-details-com/>
</template>

<script>
import ServiceDetailsCom from "../components//ServiceDetailsCom/index"
export default {
    components:{
        ServiceDetailsCom
    }
}
</script>

<style>

</style>