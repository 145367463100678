<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="row">
                <div
                    class="col-lg-6"
                    v-for="(item, index) in news"
                    :key="index"
                >
                    <div class="post-item-1">
                        <router-link :to="`/news-detail/${item.id}`" style="width: 100%;"
                            ><img :src="item.image" alt=""
                        /></router-link>
                        <div class="b-post-details">
                            <div class="bp-meta">
                                <a href="#"
                                    ><i class="fal fa-clock"></i
                                    >{{ item.date }}</a
                                >
                                <a href="#"
                                    ><i class="fal fa-comments"></i
                                    >{{ item.source }}</a
                                >
                            </div>
                            <h3>
                                <router-link :to="`/news-detail/${item.id}`">{{
                                    item.title
                                }}</router-link>
                            </h3>
                            <router-link
                                class="read-more"
                                :to="`/news-detail/${item.id}`"
                                >查看<i class="fal fa-arrow-right"></i
                            ></router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="bisylms-pagination">
                        <span class="current">01</span>
                        <!-- <a href="#">02</a> -->
                        <a class="next" href="#"
                            >next<i class="fal fa-arrow-right"></i
                        ></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import news from "@/datas/news.js";

export default {
    data() {
        // console.log(news)
        return {
            news,
        };
    },
    methods: {},
};
</script>

<style></style>
