<template>
    <!--====== APPIE FEATURES PART START ======-->
    <section class="appie-features-area pt-100" id="features">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-3">
                    <div class="appie-features-tabs-btn">
                        <div
                            class="nav flex-column nav-pills"
                            role="tablist"
                            aria-orientation="vertical"
                        >
                            <a
                                @click.prevent="selectFeature('setting')"
                                class="nav-link"
                                :class="[
                                    selectedTab === 'setting' ? 'active' : '',
                                ]"
                                data-toggle="pill"
                                role="tab"
                                aria-controls="v-pills-home"
                                aria-selected="true"
                                ><i class="iconfont icon-zhengshu"></i> 资质认定证书</a
                            >
                            <a
                                @click.prevent="selectFeature('report')"
                                class="nav-link"
                                :class="[
                                    selectedTab === 'report' ? 'active' : '',
                                ]"
                                data-toggle="pill"
                                role="tab"
                                aria-controls="v-pills-profile"
                                aria-selected="false"
                                ><i class="iconfont icon-shiyan"></i>
                                实验室认可证书</a
                            >
                            <a
                                @click.prevent="selectFeature('notice')"
                                class="nav-link"
                                :class="[
                                    selectedTab === 'notice' ? 'active' : '',
                                ]"
                                data-toggle="pill"
                                role="tab"
                                aria-controls="v-pills-messages"
                                aria-selected="false"
                                ><i class="iconfont icon-shiyan"></i>
                                实验室认可证书英文版</a
                            >
                            <a
                                @click.prevent="selectFeature('lock')"
                                class="nav-link"
                                :class="[
                                    selectedTab === 'lock' ? 'active' : '',
                                ]"
                                data-toggle="pill"
                                role="tab"
                                aria-controls="v-pills-settings"
                                aria-selected="false"
                                ><i class="iconfont icon-gongzhengchu"></i> 公正性声明
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="tab-content">
                        <div
                            :class="[
                                selectedTab === 'setting' ? 'active show' : '',
                            ]"
                            class="tab-pane fade"
                            role="tabpanel"
                            aria-labelledby="v-pills-home-tab"
                        >
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div
                                        class="appie-features-thumb text-center wow animated fadeInUp"
                                        data-wow-duration="2000ms"
                                        data-wow-delay="200ms"
                                    >
                                        <img
                                            src="http://www.dlhxzb.cn/new/static/uploads/20180823060515_598841854043765346.jpg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div
                                        class="appie-features-content wow animated fadeInRight"
                                        data-wow-duration="2000ms"
                                        data-wow-delay="600ms"
                                    >
                                        <span>内蒙古恒鑫产品质量检验中心</span>
                                        <h3 class="title">资质认定证书</h3>
                                        <p>
                                            China Inspection Body and Laboratory
                                            Mandatory Approval
                                        </p>

                                        <a class="main-btn" href="#">
                                            了解更多
                                        </a>
                                        <!-- <router-link
                                            class="main-btn"
                                            to-bak="/about-us"
                                            href="#"
                                        >
                                            了解更多
                                        </router-link> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            :class="[
                                selectedTab === 'report' ? 'active show' : '',
                            ]"
                            class="tab-pane fade"
                            role="tabpanel"
                            aria-labelledby="v-pills-profile-tab"
                        >
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div
                                        class="appie-features-thumb text-center animated fadeInUp"
                                        data-wow-duration="2000ms"
                                        data-wow-delay="200ms"
                                    >
                                        <img
                                            src="http://www.dlhxzb.cn/new/static/uploads/20180823060825_133592571539293771.jpg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div
                                        class="appie-features-content animated fadeInRight"
                                        data-wow-duration="2000ms"
                                        data-wow-delay="600ms"
                                    >
                                        <span>内蒙古恒鑫产品质量检验中心</span>
                                        <h3 class="title">实验室认可证书</h3>
                                        <p>
                                            China National Accreditation Service
                                            for Conformity Assessment
                                        </p>
                                        <a class="main-btn" href="#">
                                            了解更多
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            :class="[
                                selectedTab === 'notice' ? 'active show' : '',
                            ]"
                            class="tab-pane fade"
                            role="tabpanel"
                            aria-labelledby="v-pills-messages-tab"
                        >
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div
                                        class="appie-features-thumb text-center animated fadeInUp"
                                        data-wow-duration="2000ms"
                                        data-wow-delay="200ms"
                                    >
                                        <img
                                            src="http://www.dlhxzb.cn/new/static/uploads/20180823061342_308845867096824895.jpg "
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div
                                        class="appie-features-content animated fadeInRight"
                                        data-wow-duration="2000ms"
                                        data-wow-delay="600ms"
                                    >
                                        <span>内蒙古恒鑫产品质量检验中心</span>
                                        <h3 class="title">
                                            实验室认可证书英文版
                                        </h3>
                                        <p>
                                            China National Accreditation Service
                                            for Conformity Assessment
                                        </p>
                                        <a class="main-btn" href="#">
                                            了解更多
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            :class="[
                                selectedTab === 'lock' ? 'active show' : '',
                            ]"
                            class="tab-pane fade"
                            role="tabpanel"
                            aria-labelledby="v-pills-settings-tab"
                        >
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div
                                        class="appie-features-thumb text-center animated fadeInUp"
                                        data-wow-duration="2000ms"
                                        data-wow-delay="200ms"
                                    >
                                        <img
                                            src="http://www.dlhxzb.cn/new/uploads/4.jpg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div
                                        class="appie-features-content animated fadeInRight"
                                        data-wow-duration="2000ms"
                                        data-wow-delay="600ms"
                                    >
                                        <span>内蒙古恒鑫产品质量检验中心</span>
                                        <h3 class="title">公正性声明</h3>
                                        <p>
                                            科学、公正、准确、及时是我公司的质量方针
                                        </p>
                                        <a class="main-btn" href="#">
                                            了解更多
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="features-shape-1">
            <img src="@/assets/images/shape/shape-6.png" alt="" />
        </div>
        <div class="features-shape-2">
            <img src="@/assets/images/shape/shape-7.png" alt="" />
        </div>
        <div class="features-shape-3">
            <img src="@/assets/images/shape/shape-8.png" alt="" />
        </div>
    </section>

    <!--====== APPIE FEATURES PART ENDS ======-->
</template>

<script>
export default {
    data() {
        return {
            selectedTab: "setting",
            switchPlan: true,
        };
    },
    methods: {
        selectFeature(name) {
            this.selectedTab = name;
        },
    },
};
</script>

<style></style>
