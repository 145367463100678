<template>
    <div>
        <!--====== PRELOADER PART START ======-->

        <!-- <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>
        <div class="layer layer-three"><span class="overlay"></span></div>
    </div> -->

        <!--====== PRELOADER PART ENDS ======-->

        <!--====== OFFCANVAS MENU PART START ======-->

        <sidebar-home-one
            :sidebar="sidebar"
            @toggleSidebar="toggleSidebar"
            :menuItems="navs"
        />

        <!--====== OFFCANVAS MENU PART ENDS ======-->

        <!--====== PART START ======-->

        <header-news @toggleSidebar="toggleSidebar" :menuItems="navs" />

        <!--====== PART ENDS ======-->

        <!--====== APPIE PAGE TITLE PART START ======-->
        <!-- use TitleArea component with props -->
        <div class="appie-page-title-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="appie-page-title-item">
                            <h3 class="title">{{ item.title }}</h3>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item">
                                        <a href="/">首页</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="/news">新闻资讯</a>
                                    </li>
                                    <li
                                        class="breadcrumb-item active"
                                        aria-current="page"
                                    >
                                        {{ item.title }}
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--====== APPIE PAGE TITLE PART ENDS ======-->

        <!-- Blog Start -->
        <section class="blogpage-section">
            <div class="container">
                <div class="row">
                    <div class="col-12" v-html="item.content"></div>
                    <!-- <div class="col-lg-4 col-md-5">
            <blog-sidebar />
          </div> -->
                </div>
            </div>
        </section>
        <!-- Blog End -->

        <!--====== APPIE FOOTER PART START ======-->
        <footer-home-one />

        <!--====== APPIE FOOTER PART ENDS ======-->

        <!--====== APPIE BACK TO TOP PART ENDS ======-->
        <div class="back-to-top">
            <a href="#"><i class="fal fa-arrow-up"></i></a>
        </div>
        <!--====== APPIE BACK TO TOP PART ENDS ======-->
        <notice />
        <query />
    </div>
</template>

<script>
import news from "@/datas/news.js";
import navs from "@/datas/navs.json";
// import SingleBlog from "../Blogs/SingleBlog.vue";
// import BlogSidebar from "../BlogSidebar.vue";
import FooterHomeOne from "../HomeOne/FooterHomeOne.vue";
import SidebarHomeOne from "../HomeOne/SidebarHomeOne.vue";
import HeaderNews from "../News/HeaderNews.vue";
import query from "../query.vue";
import notice from "../notice.vue";
export default {
    components: {
        SidebarHomeOne,
        HeaderNews,
        FooterHomeOne,
        query,
        notice,
        // SingleBlog,
        // BlogSidebar,
    },
    data() {
        console.log(this.$route);
        let item = {};
        for (let index = 0; index < news.length; index++) {
            const element = news[index];
            if (element.id == this.$route.params.id) {
                item = element;
            }
        }
        return {
            sidebar: false,
            navs,
            item,
        };
    },
    mounted() {
        document.addEventListener("scroll", this.topToBottom);
    },
    methods: {
        topToBottom() {
            const result = document.querySelector(".back-to-top");
            if (
                document.body.scrollTop > window.innerHeight ||
                document.documentElement.scrollTop > window.innerHeight
            ) {
                result.style.display = "block";
            } else {
                result.style.display = "none";
            }
        },
        toggleSidebar() {
            this.sidebar = !this.sidebar;
        },
    },
};
</script>

<style></style>
