<template>
    <b-modal
        id="query"
        title="证书查询"
        :centered="true"
        @ok="query"
        cancel-title="取消"
        ok-title="查询"
        size-bak="sm"
    >
        <b-form-input
            v-model="query_id"
            placeholder="请输入证书编号"
        ></b-form-input>
    </b-modal>
</template>

<script>
export default {
    props: {
        menuItems: {
            type: Array,
        },
        nasted: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            query_id: "",
        };
    },
    methods: {
        query(bvModalEvent) {
            if (this.query_id) {
                console.log(this.query_id);
                let url = `https://www.zbtesting.com/api/Ask?cer_no=${this.query_id}&cer_code=-1&source=dlhxzbcn&client_ip=127.0.0.1&v=3`;
                window.open(url);
                this.query_id = "";
                return this;
            }
            alert("请输入查询编号");
            // const toast = this.$bvToast.toast("请输入查询编号", {
            //     id: "abc",
            //     title: `错误`,
            //     autoHideDelay: 11111110,
            //     appendToast: true,
            //     variant: "danger",
            //     solid: true,
            //     visible: true,
            // });
            // window.$bvToast= this.$bvToast
            // this.$bvToast.show("abc");
            // console.log(toast);
            return bvModalEvent.preventDefault();
        },
    },
};
</script>

<style>
.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
}
button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}
</style>
