<template>
  <div>
          <!--====== APPIE PAGE TITLE PART START ======-->

    <div class="appie-page-title-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-page-title-item">
                        <h3 class="title">新闻资讯</h3>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">首页</a></li>
                                <li class="breadcrumb-item active" aria-current="page">新闻资讯</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--====== APPIE PAGE TITLE PART ENDS ======-->
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
