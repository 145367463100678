
<template>
      <div class="appie-page-title-area appie-page-service-title-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-page-title-item">
                        <span>Fast Development</span>
                        <h3 class="title">Simple but effective, <br> effortless yet powerful.</h3>
                        <div class="thumb">
                            <img src="assets/images/fun-fact-thumb.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>