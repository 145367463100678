<template>
    <single-news/>
</template>

<script>
import SingleNews from '../components/NewsDetail/index'
export default {
	components: {SingleNews  },

}
</script>

<style>

</style>
