<template>
    <!--====== APPIE FOOTER PART START ======-->

    <section class="appie-footer-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-about-widget">
                        <div class="logo">
                            <a href="#"
                                ><img src="@/assets/images/nmhxpg.png" alt=""
                            /></a>
                        </div>
                        <p>
                            内蒙古恒鑫产品质量检验中心，成立于2021年9月，由恒鑫珠宝首饰鉴定评估集团公司成立，中心是在内蒙古自治区财政厅、中国资产评估协会和珠宝首饰艺术品评估专业委员会的指导及管理下，面向全社会提供珠宝艺术品资产评估及相关鉴定业务的珠宝首饰艺术品资产评估专业机构。
                        </p>
                        <a href="#"
                            >了解更多 <i class="fal fa-arrow-right"></i
                        ></a>
                        <!-- <div class="social mt-30">
                            <ul>
                                <li>
                                    <a href="#"
                                        ><i class="fab fa-facebook-f"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#"
                                        ><i class="fab fa-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#"
                                        ><i class="fab fa-pinterest-p"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#"
                                        ><i class="fab fa-linkedin-in"></i
                                    ></a>
                                </li>
                            </ul>
                        </div> -->
                    </div>
                </div>
                <div class="col-lg-2 col-md-6">
                    <div class="footer-navigation">
                        <h4 class="title">公司</h4>
                        <ul>
                            <li>
                                <router-link to="#/about-us">
                                    关于我们
                                </router-link>
                            </li>
                            <li>
                                <router-link to="#/service-details">
                                    评估服务
                                </router-link>
                            </li>
                            <!-- <li><a href="#"> 客户案例 </a></li> -->
                            <!-- <li><router-link to="#/news">Blog</router-link></li> -->
                            <li>
                                <router-link to="#/contact">
                                    联系我们
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="footer-navigation">
                        <h4 class="title">业务介绍</h4>
                        <ul>
                            <li><a href="#">珠宝首饰鉴定评估</a></li>
                            <li><a href="#">贵金属鉴定评估</a></li>
                            <li><a href="#">手表鉴定评估</a></li>
                            <li><a href="#">烟酒鉴定评估</a></li>
                            <li><a href="#">书画艺术品鉴定评估</a></li>
                            <li><a href="#">邮票钱币鉴定评估</a></li>
                            <li><a href="#">瓷器青铜器鉴定评估</a></li>
                            <li><a href="#">木质制品鉴定评估</a></li>
                            <li><a href="#">奢侈品鉴定评估</a></li>
                            <li><a href="#">其他评估</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="footer-widget-info">
                        <h4 class="title">联系方式</h4>
                        <ul>
                            <li>
                                <a href="#">
                                    <i class="fal fa-envelope"></i>
                                    nmhengxin2021@163.com
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-phone"></i>
                                    +(86) 0471 3463271
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-map-marker-alt"> </i>
                                    呼和浩特市赛罕区新华东街26号万达广场B商业综合楼14层B座1403室
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div
                        class="footer-copyright d-flex align-items-center justify-content-between pt-35"
                    >
                        <div class="apps-download-btn">
                            <!-- <ul>
                                <li>
                                    <a href="#"
                                        ><i class="fab fa-apple"></i> Download
                                        for iOS</a
                                    >
                                </li>
                                <li>
                                    <a class="item-2" href="#"
                                        ><i class="fab fa-google-play"></i>
                                        Download for Android</a
                                    >
                                </li>
                            </ul> -->
                        </div>
                        <div class="copyright-text">
                            <p>Copyright © 2022 nmhxpg.com. All rights reserved. <a href="https://beian.miit.gov.cn/" target="_blank">蒙ICP备2022002101号-1</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!--====== APPIE FOOTER PART ENDS ======-->
</template>

<script>
export default {};
</script>

<style></style>
