<template>
    <section class="appie-testimonial-area pt-100 pb-160" id="testimonial">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div
                        class="appie-testimonial-slider"
                        style="position: relative"
                    >
                        <h3 class="title" style="color: white">关于我们</h3>
                        <span style="color: white; text-indent: 2em">
                            内蒙古恒鑫产品质量检验中心，成立于2021年9月，由恒鑫珠宝首饰鉴定评估集团公司成立，中心是在内蒙古自治区财政厅、中国资产评估协会和珠宝首饰艺术品评估专业委员会的指导及管理下，面向全社会提供珠宝艺术品资产评估及相关鉴定业务的珠宝首饰艺术品资产评估专业机构。本中心是目前国内五家珠宝艺术品资产评估专营机构之一，是内蒙古自治区内唯一一家从事珠宝艺术品资产评估的专业机构；拥有国家资产评估师(珠宝)及珠宝评估专业人员8名，其中2名珠宝评估师取得全球最负盛名的美国评估师协会(American
                            So ciety
                            ofAppraisers，简称ASA）颁发的“国际珠宝首饰高级评估师证书”。集团下恒鑫珠宝首饰鉴定评估(大连)有限责任公司成立20年来，受公安，检查，法院，海关等多部门委托，完成涉及的大案要案的鉴定评估就有近万例；通过国家实验室认证认可，出具鉴定结果全球互认。公正、科学、准确、高效是内蒙古中心和大连中心的工作方针。根据财政部的相关规定，恒鑫质监资产评估中心是最高级别的资产评估机构，具有从事涉:国有资产、上市公司、抵押担保等影响公众利益和国家利益的珠宝首饰艺术品评估的资格资质。珠宝艺术品相关资产评估业务范围：司法鉴定、不良资产评估、企业融资、清算、保险、典当、拍卖、投资收藏、批零交易、司法评估及相关咨询等资产评估。
                        </span>
                        <a class="main-btn" href="#"> 了解更多 </a>
                    </div>
                    <!-- <div class="appie-testimonial-slider" style="position:relative">
                        <span class="prev slick-arrow" style="display: block;" @click.prevent="showNext"><i class="fal fa-arrow-left"></i></span>
                        <VueSlickCarousel v-bind="settings" ref="carousel">
                            <template v-for="(sliderContent, index) in sliderContents">
                                <div :key="index" class="appie-testimonial-item text-center">
                                    <div class="author-info">
                                        <img v-if="sliderContent.avatar" :src="sliderContent.avatar" alt=""/>
                                        <h5 class="title">{{sliderContent.title}}</h5>
                                        <span>{{sliderContent.date}}</span>
                                    </div>
                                    <div class="text">
                                        <p>{{sliderContent.description}}</p>
                                        <ul>
                                            <li v-for="(rating,index) in sliderContent.ratings" :key="index">
                                                <i class="fas fa-star"></i>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </template>
                        </VueSlickCarousel>
                        <span @click.prevent="showPrev" class="next slick-arrow" style="display: block;"><i class="fal fa-arrow-right"></i></span>
                    </div> -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
// import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
    // components: { VueSlickCarousel },
    props: {
        sliderContents: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {
            settings: {
                autoplay: true,
                arrows: false,
                dots: true,
            },
        };
    },
    methods: {
        showNext() {
            this.$refs.carousel.next();
        },
        showPrev() {
            this.$refs.carousel.prev();
        },
    },
};
</script>

<style>
.slick-dots li button:before {
    content: "";
}
.appie-testimonial-slider .slick-arrow.prev {
    left: 20%;
}
.appie-testimonial-slider .slick-arrow {
    top: 50%;
}
</style>
