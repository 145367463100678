<template>
  <home-eight/>
</template>

<script>
import HomeEight from "../components/HomeEight/index"
export default {
    components: {HomeEight},
}
</script>

<style>

</style>