<template>
    <!--====== APPIE HERO PART START ======-->
    <section class="appie-hero-area pt-0 pb-0">
        <div class="container-bak container-new">
            <b-carousel
                id="carousel-1"
                v-model="slide"
                :interval="4000"
                controls
                indicators
                background="#ababab"
                img-width="1024"
                img-height="480"
                style="text-shadow: 1px 1px 2px #333"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
            >
                <!-- Text slides with image -->
                <b-carousel-slide
                    caption-bak="First slide"
                    text-bak="Nulla vitae elit libero, a pharetra augue mollis interdum."
                    :img-src="img1"
                ></b-carousel-slide>

                <!-- Slides with custom text -->
                <b-carousel-slide :img-src="img2">
                    <!-- <h1>Hello world!</h1> -->
                </b-carousel-slide>
                <!-- <b-carousel-slide
                    caption="恒鑫珠宝首饰评估"
                    text="专业珠宝首饰艺术品评估机构"
                    :img-src="img3"
                ></b-carousel-slide>
                <b-carousel-slide
                    caption="恒鑫珠宝首饰评估"
                    text="专业珠宝首饰艺术品评估机构"
                    :img-src="img4"
                ></b-carousel-slide>
                <b-carousel-slide
                    caption="恒鑫珠宝首饰评估"
                    text="专业珠宝首饰艺术品评估机构"
                    :img-src="img5"
                ></b-carousel-slide> -->

                <!-- Slides with image only -->
                <!-- <b-carousel-slide
                    img-src="https://my-cache-1251059917.file.myqcloud.com/nmhxpg/images/about-page-thumb.jpg"
                ></b-carousel-slide> -->

                <!-- Slides with img slot -->
                <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
                <!-- <b-carousel-slide>
                    <template #img>
                        <img
                            class="d-block img-fluid w-100"
                            width="1024"
                            height="480"
                            src="https://my-cache-1251059917.file.myqcloud.com/nmhxpg/images/about-page-thumb.jpg"
                            alt="image slot"
                        />
                    </template>
                </b-carousel-slide> -->

                <!-- Slide with blank fluid image to maintain slide aspect ratio -->
                <!-- <b-carousel-slide
                    caption="Blank Image"
                    img-blank
                    img-alt="Blank image"
                >
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse eros felis, tincidunt a tincidunt eget,
                        convallis vel est. Ut pellentesque ut lacus vel
                        interdum.
                    </p>
                </b-carousel-slide> -->
            </b-carousel>
        </div>
    </section>
    <!--====== APPIE HERO PART end ======-->
</template>

<script>
import img1 from "@/assets/images/1.jpeg";
import img2 from "@/assets/images/2.jpeg";
// import img3 from "@/assets/images/3.jpeg";
// import img4 from "@/assets/images/4.jpeg";
// import img5 from "@/assets/images/5.jpeg";
export default {
    props: [
        "welcome_message",
        "slogan",
        "description",
        "thumb_one",
        "thumb_two",
        "first_button_content",
        "first_button_icon",
        "second_button_content",
        "second_button_icon",
    ],
    data() {
        return {
            slide: null,
            img1,
            img2,
            // img3,
            // img4,
            // img5,
        };
    },
    methods: {
        onSlideStart() {},
        onSlideEnd() {},
    },
};
</script>

<style>
.carousel-indicators li {
    box-sizing: content-box;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
}
.container-new h3 {
    color: #f9f9f9;
}
.container-new p {
    color: #cfcfcf;
}
</style>
