<template>
    <div>
        <div v-if="showloading">
            <loader-appie />
        </div>
        <div v-else>
            <!--====== PRELOADER PART START ======-->

            <!-- <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>
        <div class="layer layer-three"><span class="overlay"></span></div>
    </div> -->

            <!--====== PRELOADER PART ENDS ======-->

            <!--====== OFFCANVAS MENU PART START ======-->

            <sidebar-home-one
                :sidebar="sidebar"
                @toggleSidebar="toggleSidebar"
                :menuItems="navs"
            />

            <!--====== OFFCANVAS MENU PART ENDS ======-->

            <!--====== PART START ======-->

            <header-home-one @toggleSidebar="toggleSidebar" :menuItems="navs" />

            <!--====== PART ENDS ======-->

            <!--====== APPIE HERO PART START ======-->

            <hero-home-one />

            <!--====== APPIE HERO PART ENDS ======-->

            <!--====== APPIE SERVICES PART START ======-->
            <services-home-one
                :grid_items="itemsGrid"
                section_heading="鉴定评估服务"
                description="公正、科学、准确、高效"
            />
            <!--====== APPIE SERVICES PART ENDS ======-->

            <!--====== APPIE FEATURES PART START ======-->
            <features-home-one />

            <!--====== APPIE FEATURES PART ENDS ======-->

            <!--====== APPIE TRAFFIC PART START ======-->

            <!-- <traffic-home-one
        :traffic_thumbnail="traffic_thumb"
        small_title="Traffic"
        big_title="Stage reporting with  50k queries."
        description="He nicked it tickety boo harry the cras bargy chap mush spiffing spend a penny the full monty burke butty."
        :features="features"
      /> -->

            <!--====== APPIE TRAFFIC PART ENDS ======-->

            <!--====== APPIE TESTIMONIAL PART START ======-->

            <testimonial-home-one :sliderContents="sliderContents" />

            <!--====== APPIE TESTIMONIAL PART ENDS ======-->

            <!--====== APPIE TEAM PART START ======-->

            <team-home-one
                title="珠宝鉴定"
                description="展示部分精选送检样品."
                :members="members"
            />

            <!--====== APPIE TEAM PART ENDS ======-->

            <!--====== APPIE PRICING PART START ======-->

            <priceing-home-one
                title="珠宝首饰艺术品评估流程"
                description="咨询热线 0471-3463271"
            />

            <!--====== APPIE PRICING PART ENDS ======-->

            <!--====== APPIE FAQ PART START ======-->

            <!-- <faq-home-one title="问题解答" description="客户常见问题" /> -->

            <!--====== APPIE FAQ PART ENDS ======-->

            <!--====== APPIE BLOG PART START ======-->

            <blog-home-one
                title="新闻资讯"
                description="行业新闻资讯及业内动态等信息."
                :blogs="blogs"
            />

            <!--====== APPIE BLOG PART ENDS ======-->

            <!--====== APPIE PROJECT PART START ======-->

            <!-- <project-home-one
                title="Start your project with appie."
                description="We will email you only about this product."
            /> -->

            <!--====== APPIE PROJECT PART ENDS ======-->

            <!--====== APPIE FOOTER PART START ======-->

            <footer-home-one />

            <!--====== APPIE FOOTER PART ENDS ======-->

            <!--====== APPIE BACK TO TOP PART ENDS ======-->
            <div class="back-to-top">
                <a href="#"><i class="fal fa-arrow-up"></i></a>
            </div>
            <notice />
            <query />
            <div @click="$bvModal.show('notice')">notice</div>
            <!--====== APPIE BACK TO TOP PART ENDS ======-->
        </div>
    </div>
</template>
<script>
import blogOne from "@/assets/images/fc.png";
import blogTwo from "@/assets/images/zs.png";
import blogThree from "@/assets/images/hsh.png";
import memberOne from "@/assets/images/team-1.jpg";
import memberTwo from "@/assets/images/team-2.jpg";
import memberThree from "@/assets/images/team-3.jpg";
import memberFour from "@/assets/images/team-4.jpg";
import testmonialAvater from "@/assets/images/testimonial-user.png";
import traffic_thumb from "@/assets/images/traffic-thumb.png";
import icon_one from "@/assets/images/icon/1.png";
import icon_two from "@/assets/images/icon/2.png";
import icon_three from "@/assets/images/icon/3.png";
import icon_four from "@/assets/images/icon/4.png";
import icon_5 from "@/assets/images/icon/5.png";
import icon_6 from "@/assets/images/icon/6.png";
import icon_7 from "@/assets/images/icon/7.png";
import icon_8 from "@/assets/images/icon/8.png";
import icon_9 from "@/assets/images/icon/9.png";
import HeaderHomeOne from "./HeaderHomeOne.vue";
import SidebarHomeOne from "./SidebarHomeOne.vue";
import HeroHomeOne from "./HeroHomeOne.vue";
import ServicesHomeOne from "./ServicesHomeOne.vue";
import FeaturesHomeOne from "./FeaturesHomeOne.vue";
// import TrafficHomeOne from "./TrafficHomeOne.vue";
import TestimonialHomeOne from "./TestimonialHomeOne.vue";
import TeamHomeOne from "./TeamHomeOne.vue";
import PriceingHomeOne from "./PriceingHomeOne.vue";
// import FaqHomeOne from "./FaqHomeOne.vue";
import BlogHomeOne from "./BlogHomeOne.vue";
// import ProjectHomeOne from "./ProjectHomeOne.vue";
import FooterHomeOne from "./FooterHomeOne.vue";
import LoaderAppie from "../LoaderAppie.vue";
import query from "../query.vue";
import notice from "../notice.vue";
import navs from "@/datas/navs.json";
export default {
    name: "HomeOne",
    props: {
        rtl: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        HeaderHomeOne,
        SidebarHomeOne,
        HeroHomeOne,
        ServicesHomeOne,
        FeaturesHomeOne,
        // TrafficHomeOne,
        TestimonialHomeOne,
        TeamHomeOne,
        PriceingHomeOne,
        // FaqHomeOne,
        BlogHomeOne,
        // ProjectHomeOne,
        FooterHomeOne,
        LoaderAppie,
        query,
        notice,
    },
    data() {
        return {
            traffic_thumb: traffic_thumb,
            testmonialAvater: testmonialAvater,
            sidebar: false,
            showloading: true,
            navs: navs,
            itemsGrid: [
                {
                    count: 1,
                    icon: icon_one,
                    title: "珠宝首饰鉴定评估",
                    content: "点击查看珠宝首饰鉴定评估的介绍.",
                    customClass: "",
                },
                {
                    count: 2,
                    icon: icon_two,
                    title: "贵金属鉴定评估",
                    content: "点击查看贵金属鉴定评估的介绍.",
                    customClass: "item-2",
                },
                {
                    count: 3,
                    icon: icon_three,
                    title: "手表鉴定评估",
                    content: "点击查看手表鉴定评估的介绍.",
                    customClass: "item-3",
                },
                {
                    count: 4,
                    icon: icon_four,
                    title: "烟酒鉴定评估",
                    content: "点击查看烟酒鉴定评估的介绍.",
                    customClass: "item-4",
                },
                {
                    count: 5,
                    icon: icon_5,
                    title: "书画艺术品鉴定评估",
                    content: "点击查看书画艺术品鉴定评估的介绍.",
                    customClass: "item-5",
                },
                {
                    count: 6,
                    icon: icon_6,
                    title: "邮票钱币鉴定评估",
                    content: "点击查看邮票钱币鉴定评估的介绍.",
                    customClass: "item-6",
                },
                {
                    count: 7,
                    icon: icon_7,
                    title: "瓷器青铜器鉴定评估",
                    content: "点击查看瓷器青铜器鉴定评估的介绍.",
                    customClass: "item-7",
                },
                {
                    count: 8,
                    icon: icon_8,
                    title: "木质制品鉴定评估",
                    content: "点击查看木质制品鉴定评估的介绍.",
                    customClass: "item-8",
                },
                {
                    count: 9,
                    icon: icon_9,
                    title: "奢侈品鉴定评估",
                    content: "点击查看奢侈品鉴定评估的介绍.",
                    customClass: "item-9",
                },
            ],
            features: [
                {
                    title: "Carefully designed",
                    bg: "", // create class for style
                    description: "Mucker plastered bugger all mate morish are.",
                },
                {
                    title: "Choose a App",
                    bg: "item-2", // create class for style
                    description: "Mucker plastered bugger all mate morish are.",
                },
                {
                    title: "Seamless Sync",
                    bg: "item-3", // create class for style
                    description: "Mucker plastered bugger all mate morish are.",
                },
                {
                    title: "User Interactive",
                    bg: "item-4", // create class for style
                    description: "Mucker plastered bugger all mate morish are.",
                },
            ],
            sliderContents: [
                {
                    title: "Jason Response",
                    date: "April 14, 2022",
                    description:
                        "Why I say old chap that is spiffing lurgy Oxford cracking goal bleeder, bog-standard Harry brilliant mush a blinding shot starkers.",
                    ratings: 5,
                    avatar: testmonialAvater,
                },
                {
                    title: "Jason Response",
                    date: "April 14, 2022",
                    description:
                        "Why I say old chap that is spiffing lurgy Oxford cracking goal bleeder, bog-standard Harry brilliant mush a blinding shot starkers.",
                    ratings: 5,
                    avatar: testmonialAvater,
                },
                {
                    title: "Jason Response",
                    date: "April 14, 2022",
                    description:
                        "Why I say old chap that is spiffing lurgy Oxford cracking goal bleeder, bog-standard Harry brilliant mush a blinding shot starkers.",
                    ratings: 2,
                    avatar: testmonialAvater,
                },
                {
                    title: "Jason Response",
                    date: "April 14, 2022",
                    description:
                        "Why I say old chap that is spiffing lurgy Oxford cracking goal bleeder, bog-standard Harry brilliant mush a blinding shot starkers.",
                    ratings: 3,
                    avatar: testmonialAvater,
                },
            ],
            memberOne: memberOne,
            memberTwo: memberTwo,
            memberThree: memberThree,
            memberFour: memberFour,
            members: [
                {
                    name: "彩色宝石评估",
                    position: "样品说明",
                    img: memberTwo,
                    socials: [],
                },
                {
                    name: "贵金属评估",
                    position: "样品说明",
                    img: memberOne,
                    socials: [],
                },
                {
                    name: "有机宝石评估",
                    position: "样品说明",
                    img: memberThree,
                    socials: [],
                },
                {
                    name: "钻石评估",
                    position: "样品说明",
                    img: memberFour,
                    socials: [],
                },
            ],
            blogOne: blogOne,
            blogTwo: blogTwo,
            blogThree: blogThree,
            blogs: [
                {
                    img: require("@/assets/images/def.png"),
                    date: "2022年05月11日",
                    category: "中国会计报",
                    path: "/news-detail/1",
                    title: "为行业自律管理提供有效抓手——《中国资产评估协会资产评估业务报备管理办法》系列解读（二）",
                },
                {
                    img: require("@/assets/images/def.png"),
                    date: "2022年05月11日",
                    category: "中国会计报",
                    path: "/news-detail/2",
                    title: "完善内部管理制度 规范评估行业发展——《中国资产评估协会资产评估业务报备管理办法》系列解读（三）",
                },
                {
                    img: "https://mmbiz.qpic.cn/mmbiz_png/o4Gdz8ngs0lKRkOticxSJcT7nLIcZkvcf2ocFGpGFZHSyKiaPA9vY6PUXyiceRUqLqgCuUyeGLw3X6vxjwJJownqA/640?wx_fmt=png",
                    date: "2022年02月22日",
                    category: "中国资产评估协会",
                    path: "/news-detail/3",
                    title: "中评协关于印发《中国资产评估协会2022年培训计划》并做好有关工作的通知",
                },
                {
                    img: blogOne,
                    date: "2022年05月11日",
                    category: "翡翠",
                    title: "2020翡翠制品评价规则标准修订编制说明",
                },
                {
                    img: blogTwo,
                    date: "2022年05月11日",
                    category: "钻石",
                    title: "钻石的产地分布有哪些",
                },
                {
                    img: blogThree,
                    date: "2022年05月11日",
                    category: "珊瑚",
                    title: "红珊瑚的实用鉴定与保养",
                },
            ],
        };
    },
    created() {
        if (!this.rtl) {
            this.preLoading();
        } else {
            this.showloading = false;
        }
        // console.log(navs)
        document.addEventListener("scroll", this.topToBottom);
    },
    mounted() {
        // setTimeout(() => {
        //     this.$bvModal.show("notice1");
        //     this.$bvModal.show("notice0");
        // }, 2000);
    },
    methods: {
        preLoading() {
            setTimeout(() => {
                this.showloading = false;
            }, 2000);
        },
        topToBottom() {
            const result = document.querySelector(".back-to-top");
            if (
                document.body.scrollTop > window.innerHeight ||
                document.documentElement.scrollTop > window.innerHeight
            ) {
                result.style.display = "block";
            } else {
                result.style.display = "none";
            }
        },
        toggleSidebar() {
            this.sidebar = !this.sidebar;
        },
    },
};
</script>

<style></style>
