<template>
  <!--====== APPIE FOOTER PART START ======-->
  <section class="appie-footer-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="footer-about-widget">
            <div class="logo">
              <a href="#"><img src="@/assets/images/logo.png" alt="" /></a>
            </div>
            <p>Appie WordPress هو موضوع هو آخر موضوع ستحصل عليه على الإطلاق.</p>
            <a href="#">اقرأ أكثر <i class="fal fa-arrow-right"></i></a>
            <div class="social mt-30">
              <ul>
                <li>
                  <a href="#"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-twitter"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-pinterest-p"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-linkedin-in"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-6">
          <div class="footer-navigation">
            <h4 class="title">شركة</h4>
            <ul>
              <li><router-link to="/about-us">معلومات عنا</router-link></li>
              <li><router-link to="/service-details">خدمة</router-link></li>
              <li><a href="#">دراسات الحالة</a></li>
              <li><router-link to="/news">مدونة او مذكرة</router-link></li>
              <li><router-link to="/contact">اتصل</router-link></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-navigation">
            <h4 class="title">Support</h4>
            <ul>
              <li><a href="#">تواصل اجتماعي</a></li>
              <li><a href="#">موارد</a></li>
              <li><a href="#">الأسئلة الشائعة</a></li>
              <li><a href="#">سياسة خاصة</a></li>
              <li><a href="#">وظائف</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-widget-info">
            <h4 class="title">ابقى على تواصل</h4>
            <ul>
              <li>
                <a href="#"
                  ><i class="fal fa-envelope"></i> support@appie.com</a
                >
              </li>
              <li>
                <a href="#"><i class="fal fa-phone"></i> +(642) 342 762 44</a>
              </li>
              <li>
                <a href="#"
                  ><i class="fal fa-map-marker-alt"></i> 442 Belle Terre St
                  Floor 7, San Francisco, AV 4206</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div
            class="
              footer-copyright
              d-flex
              align-items-center
              justify-content-between
              pt-35
            "
          >
            <div class="apps-download-btn">
              <ul>
                <li>
                  <a href="#"><i class="fab fa-apple"></i> تنزيل لنظام iOS</a>
                </li>
                <li>
                  <a class="item-2" href="#"
                    ><i class="fab fa-google-play"></i> تنزيل لنظام Android</a
                  >
                </li>
              </ul>
            </div>
            <div class="copyright-text">
              <p>حقوق النشر © 2021 Appie. كل الحقوق محفوظة.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== APPIE FOOTER PART ENDS ======-->
</template>

<script>
export default {};
</script>

<style>
</style>