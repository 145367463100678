<template>

      <div class="appie-signup-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-signup-box">
                        <span>No credit card required</span>
                        <h3 class="title">Get Started.</h3>
                        <form action="#">
                            <div class="input-box">
                                <input type="text" placeholder="Enter your name">
                            </div>
                            <div class="input-box">
                                <input type="email" placeholder="Enter your email">
                            </div>
                            <div class="input-box">
                                <button type="submit">Sign Up</button>
                            </div>
                            <div class="appie_checkbox_common checkbox_style2">
                                <div>
                                    <input type="checkbox" name="checkbox2" id="checkbox4">
                                    <label for="checkbox4"><span></span>By signing up you agree to our <a href="#">Terms & Conditions.</a></label>
                                </div>
                            </div>
                        </form>
                        <div class="thumb">
                            <img src="@/assets/images/signup-thumb.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>