<template>
    <home-seven/>
</template>

<script>
import HomeSeven from '../components/HomeSeven/index'
export default {
	components: {HomeSeven  },

}
</script>

<style>

</style>