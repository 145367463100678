<template>
    <!--====== APPIE PRICING PART START ======-->

    <section class="appie-pricing-area pt-90 pb-90">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-section-title text-center">
                        <h3 class="appie-title">{{ title }}</h3>
                        <p>{{ description }}</p>
                    </div>
                </div>
            </div>
            <div
                class="tabed-content d-flex flex-column align-items-center justify-content-center main-order"
            >
                <div
                    v-for="(item, index) in items"
                    :key="index"
                    class="d-flex align-items-center"
                    :class="[index % 2 == 0 ? 'order-left' : 'order-right']"
                >
                    <div
                        :class="[
                            item.customClass && item.customClass !== ''
                                ? item.customClass
                                : '',
                            index % 2 == 0 ? 'order-1' : 'order-2',
                        ]"
                        class="appie-single-service text-center mt-30 wow animated fadeInUp pt-10 pb-10 pl-30 pr-30"
                        data-wow-duration="2000ms"
                        data-wow-delay="200ms"
                    >
                        <!-- <div class="icon">
                            <i
                                style="color: white; font-size: 36px"
                                :class="item.icon"
                            ></i>
                            <span>{{ item.count }}</span>
                        </div> -->
                        <h4 class="appie-title p-0 item-title">
                            {{ item.title }}
                        </h4>
                        <!-- <p>{{ item.content }}</p> -->
                    </div>

                    <div
                        v-if="index < 10"
                        class="ml-20 mr-20 mt-30"
                        :class="[index % 2 == 0 ? 'order-2' : 'order-1']"
                    >
                        <span class="step-icon"> {{ index + 1 }} </span>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="faq-text text-center pt-40">
                    <p>
                        联系地址：呼和浩特市赛罕区新华东街26号万达广场B商业综合楼14层B座1403室
                    </p>
                </div>
            </div>
        </div>
    </section>

    <!--====== APPIE PRICING PART ENDS ======-->
</template>

<script>
export default {
    props: {
        title: {
            type: String,
        },
        description: {
            type: String,
        },
    },
    data() {
        return {
            switchPlan: true,
            items: [
                {
                    count: 1,
                    icon: "iconfont icon-dianhua",
                    title: "明确业务基本事项",
                    content: "",
                    customClass: "",
                },
                {
                    count: 2,
                    icon: "iconfont icon-zhubao",
                    title: "订立业务委托合同",
                    content: "",
                    customClass: "",
                },
                {
                    count: 3,
                    icon: "iconfont icon-xieyixiangqing",
                    title: "鉴定评估客体",
                    content: "",
                    customClass: "",
                },
                {
                    count: 4,
                    icon: "iconfont icon-licai",
                    title: "开展市场调查",
                    content: "",
                    customClass: "",
                },
                {
                    count: 5,
                    icon: "iconfont icon-ico_shujuchaxunyutongji_yongcanzhuangtaichaxun",
                    title: "收集整理评估资料",
                    content: "",
                    customClass: "",
                },
                {
                    count: 6,
                    icon: "iconfont icon-baogao",
                    title: "应用评估方法",
                    content: "",
                    customClass: "",
                },
                {
                    count: 7,
                    icon: "iconfont icon-baogao",
                    title: "调整价值特征",
                    content: "",
                    customClass: "",
                },
                {
                    count: 8,
                    icon: "iconfont icon-baogao",
                    title: "评定估算形成结论",
                    content: "",
                    customClass: "",
                },
                {
                    count: 9,
                    icon: "iconfont icon-baogao",
                    title: "编制提交评估报告",
                    content: "",
                    customClass: "",
                },
                {
                    count: 10,
                    icon: "iconfont icon-baogao",
                    title: "整理归集评估档案",
                    content: "",
                    customClass: "",
                },
            ],
        };
    },
    methods: {
        change_plan() {
            this.switchPlan = !this.switchPlan;
        },
    },
};
</script>

<style>
.order-left {
    margin-left: -260px;
}
.order-right {
    margin-right: -260px;
}
.item-title {
    width: 200px;
}
.step-icon {
    text-align: center;
    height: 30px;
    width: 30px;
    background: #2b70fa;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0px 3px 6px 0px rgb(14 17 51 / 10%);
    line-height: 28px;
    color: #fff;
}

.main-order {
    height: 100%;
    position: relative;
}
.main-order::after {
    content: " ";
    width: 5px;
    background-color: white;;
    /* height: 100%; */
    max-height: max-content;
    height: inherit;
    position: absolute;
    top: 0;
    z-index: -1;
}
</style>
